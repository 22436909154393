import { typedAPI, ErrorCode } from "@markit/common.api";

const API_BASE = "https://us-central1-markit-d5e9b.cloudfunctions.net/api/";
// const API_BASE = "http://10.239.239.123:8000/"; // office
// const API_BASE = "http://192.168.1.207:8000/"; // home
// const API_BASE = "http://10.0.0.243:8000/"; // Jonathan's
// const API_BASE = "http://192.168.0.60:8000/"; // home home

const errorFunction = (errorCode: number) => {
  console.error(errorCode);
  switch (errorCode) {
    case ErrorCode.NO_TICKETS_REMAINING:
      alert("There are no more tickets remaining for this event");
      return;
    case ErrorCode.ALREADY_GOING:
      alert("You are already going to this event");
      return;
    case ErrorCode.USED_ITEM_ID:
    case ErrorCode.INVALID_ITEM_ID:
      alert(
        "Your ticket, promoter ticket, or promo code has already been redeemed or is invalid"
      );
      return;
  }
  return ErrorCode[errorCode];
};

const headers = {
  mode: process.env.NODE_ENV === "development" ? "development" : "live",
};

typedAPI.initialize(API_BASE, errorFunction, headers);

export { API_BASE, typedAPI as API };
